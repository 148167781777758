import React from "react";
import { graphql } from "gatsby";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import HeroFullWidth from "../components/Hero/HeroFullWidth";
import CallToAction from "../components/Repeating/CTA";
import ModalApply from "../components/Modal/ModalApply";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  return (
    <Layout headerStyle="overlap">
      <SearchEngineOptimization
        title="Careers | Hotel Cleaning Services | Hospitality Staffing"
        description="Looking for hotel cleaning & hospitality staffing careers? Browse Hotel Cleaning Services jobs today! We're a trusted nationwide leader in cleaning."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <HeroFullWidth
        backgroundImages={data.heroDesktop.childImageSharp.gatsbyImageData}
        padding="pt-28 md:pt-52 pb-28 md:pb-48"
        textMaxWidth="max-w-lg"
        backgroundPosition="25% 50%"
      >
        <h1 className="text-white">Now Hiring</h1>
        <p className="md:text-xl tracking-normal">
          Are you the type of person who's committed to customers and thrives on
          providing exceptional service? Come work for Hotel Cleaning Services,
          the nationwide industry leader in hotel cleaning and hospitality.
        </p>
        <ButtonSolid modal="modal-apply" text="Apply Now" />
      </HeroFullWidth>

      <section className="bg-white py-20 md:py-32">
        <div className="container">
          <header class="mb-16">
            <h2 className="text-gray-900">Open Positions</h2>
          </header>
          <Accordion
            allowZeroExpanded={true}
            className="flex flex-col border-t border-primary-400 mb-10"
          >
            <AccordionItem
              uuid={1}
              className="py-8 md:py-10 border-b border-primary-400"
            >
              <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                <p className="text-xl text-gray-900 font-bold no-underline mb-0">
                  Housekeeping
                </p>

                <AccordionItemState>
                  {(state) => {
                    const icon = state.expanded
                      ? "fa-chevron-up"
                      : "fa-chevron-down";
                    return (
                      <i
                        className={`fal ${icon} text-2xl md:text-4xl text-primary-400 ml-4`}
                      ></i>
                    );
                  }}
                </AccordionItemState>
              </AccordionItemButton>

              <AccordionItemPanel className="pt-4">
                <ul className="list-disc pl-8 text-gray-600 mb-6">
                  <li>Room Attendants</li>
                  <li>Houseman</li>
                  <li>Room Attendant Inspector</li>
                  <li>Room Attendant Supervisor</li>
                  <li>Lobby Porter</li>
                </ul>
                <ButtonSolid modal="modal-apply" text="Apply Now" />
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem
              uuid={2}
              className="py-8 md:py-10 border-b border-primary-400"
            >
              <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                <p className="text-xl text-gray-900 font-bold no-underline mb-0">
                  Culinary
                </p>
                <AccordionItemState>
                  {(state) => {
                    const icon = state.expanded
                      ? "fa-chevron-up"
                      : "fa-chevron-down";
                    return (
                      <i
                        className={`fal ${icon} text-2xl md:text-4xl text-primary-400 ml-4`}
                      ></i>
                    );
                  }}
                </AccordionItemState>
              </AccordionItemButton>

              <AccordionItemPanel className="pt-4">
                <ul className="list-disc pl-8 text-gray-600 mb-6">
                  <li>Hostess</li>
                  <li>Dishwasher</li>
                  <li>Steward</li>
                  <li>Server</li>
                  <li>Bartender</li>
                  <li>Busser/Runner</li>
                  <li>Prep Cook 1,2,3</li>
                  <li>Line Cook 1,2,3</li>
                  <li>Sou Chef</li>
                </ul>
                <ButtonSolid modal="modal-apply" text="Apply Now" />
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem
              uuid={3}
              className="py-8 md:py-10 border-b border-primary-400"
            >
              <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                <p className="text-xl text-gray-900 font-bold no-underline mb-0">
                  Engineering
                </p>
                <AccordionItemState>
                  {(state) => {
                    const icon = state.expanded
                      ? "fa-chevron-up"
                      : "fa-chevron-down";
                    return (
                      <i
                        className={`fal ${icon} text-2xl md:text-4xl text-primary-400 ml-4`}
                      ></i>
                    );
                  }}
                </AccordionItemState>
              </AccordionItemButton>

              <AccordionItemPanel className="pt-4">
                <ul className="list-disc pl-8 text-gray-600 mb-6">
                  <li>Engineering Assistant</li>
                  <li>Grounds Maintenance</li>
                </ul>
                <ButtonSolid modal="modal-apply" text="Apply Now" />
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem
              uuid={4}
              className="py-8 md:py-10 border-b border-primary-400"
            >
              <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                <p className="text-xl text-gray-900 font-bold no-underline mb-0">
                  Pool & Spa
                </p>
                <AccordionItemState>
                  {(state) => {
                    const icon = state.expanded
                      ? "fa-chevron-up"
                      : "fa-chevron-down";
                    return (
                      <i
                        className={`fal ${icon} text-2xl md:text-4xl text-primary-400 ml-4`}
                      ></i>
                    );
                  }}
                </AccordionItemState>
              </AccordionItemButton>

              <AccordionItemPanel className="pt-4">
                <ul className="list-disc pl-8 text-gray-600 mb-6">
                  <li>Pool Attendant</li>
                  <li>Spa Attendant</li>
                </ul>
                <ButtonSolid modal="modal-apply" text="Apply Now" />
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem
              uuid={5}
              className="py-8 md:py-10 border-b border-primary-400"
            >
              <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                <p className="text-xl text-gray-900 font-bold no-underline mb-0">
                  Overnight Custodial
                </p>
                <AccordionItemState>
                  {(state) => {
                    const icon = state.expanded
                      ? "fa-chevron-up"
                      : "fa-chevron-down";
                    return (
                      <i
                        className={`fal ${icon} text-2xl md:text-4xl text-primary-400 ml-4`}
                      ></i>
                    );
                  }}
                </AccordionItemState>
              </AccordionItemButton>

              <AccordionItemPanel className="pt-4">
                <ul className="list-disc pl-8 text-gray-600 mb-6">
                  <li>Public Areas Custodial</li>
                  <li>Floor Technicians</li>
                  <li>Kitchen Equipment Specialists</li>
                  <li>On-Site Supervisor</li>
                  <li>Hotel Management</li>
                </ul>
                <ButtonSolid modal="modal-apply" text="Apply Now" />
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem
              uuid={6}
              className="py-8 md:py-10 border-b border-primary-400"
            >
              <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                <p className="text-xl text-gray-900 font-bold no-underline mb-0">
                  Part Time / Special Events
                </p>
                <AccordionItemState>
                  {(state) => {
                    const icon = state.expanded
                      ? "fa-chevron-up"
                      : "fa-chevron-down";
                    return (
                      <i
                        className={`fal ${icon} text-2xl md:text-4xl text-primary-400 ml-4`}
                      ></i>
                    );
                  }}
                </AccordionItemState>
              </AccordionItemButton>

              <AccordionItemPanel className="pt-4">
                <ul className="list-disc pl-8 text-gray-600 mb-6">
                  <li>Banquets</li>
                  <li>Bartenders</li>
                  <li>Servers</li>
                  <li>Bussers</li>
                </ul>
                <ButtonSolid modal="modal-apply" text="Apply Now" />
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>

          <div className="max-w-[640px] mx-auto">
            <p className="text-center text-gray-600 text-sm tracking-normal">
              Don’t see the position you’re looking for?{" "}
              <a
                data-modal-open="modal-contact"
                className="text-gray-900 hover:text-primary-400 cursor-pointer"
              >
                Contact us
              </a>{" "}
              and let us know what you can do. We’re always looking to expand
              our team. Send your resume & filled out application form to{" "}
              <a
                href="mailto:careers@hotelcleaningservices.com"
                className="text-gray-900 hover:text-primary-400"
              >
                careers@hotelcleaningservices.com
              </a>
              .
            </p>
          </div>
        </div>
      </section>
      <CallToAction />
      <ModalApply />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Homepage.jpg" }
    ) {
      publicURL
    }
    heroDesktop: file(relativePath: { eq: "careers/1.0 Careers Hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
export default Page;
